import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuSection } from '@vendure/admin-ui/core';

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuSection({
      id: 'quotes-nav',
      label: 'Quotes',
      items: [{
        id: 'quote',
        label: 'Quotes',
        routerLink: ['/extensions/quote'],
        // Icon can be any of https://clarity.design/icons
        icon: 'folder-open',
      }],
	  requiresPermission: 'SuperAdmin',
    },
    // Add this section before the "settings" section
    'settings'),
  ]
})
export class QuoteExtensionModule {}