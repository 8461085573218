import { addNavMenuSection } from "@vendure/admin-ui/core";
export default [
  addNavMenuSection({
    id: "referral",
    label: "Referrals",
    items: [
      {
        id: "referrals",
        label: "Referrals",
        routerLink: ["/extensions/referral/referrals"],
        icon: "tags",
      },
    ],
  }),
];
