import { NgModule } from "@angular/core";
import { SharedModule, addNavMenuSection } from "@vendure/admin-ui/core";
import { Permission } from "@vendure/core";

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuSection(
      {
        id: "onblock-nav",
        label: "OnBlockitContacts",
        requiresPermission: "SuperAdmin",
        items: [
          {
            id: "onblock",
            label: "OnBlockit",
            routerLink: ["/extensions/onblockitcontact"],
            // Icon can be any of https://clarity.design/icons
            icon: "folder-open",
          },
        ],
        // requiresPermission: 'SuperAdmin',
      },
      // Add this section before the "settings" section
      "settings",
    ),
  ],
})
export class OnBlockContactExtensionModule {}
